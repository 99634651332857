'use client';
import { Locale } from '@/lib/types';
import { Button, ButtonProps } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';
import { setLocaleAction } from '@/actions';
import { useLocale } from 'next-intl';
import { ComponentProps, forwardRef, useMemo } from 'react';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import toast from 'react-hot-toast';
import { cn } from '@/lib/utils/cn';

export const LOCALES: {
  locale: Locale;
  flag: string;
  language: string;
}[] = [
  {
    locale: 'en',
    flag: '🇺🇸',
    language: 'English',
  },
  {
    locale: 'es',
    flag: '🇪🇸',
    language: 'Español',
  },
];

export const LocaleSelector = forwardRef<HTMLButtonElement, ButtonProps>(
  function LocaleSelector(
    { className, variant = 'ghost', size = 'sm', ...props },
    ref
  ) {
    const locale = useLocale();

    const currLocale = useMemo(() => {
      return LOCALES.find((l) => l.locale === locale) ?? LOCALES[0];
    }, [locale]);

    const onSelectLocale = async (selectedLocale: Locale) => {
      if (selectedLocale === locale) return;
      try {
        await setLocaleAction(selectedLocale);
      } catch (err: any) {
        console.error(err);
        toast.error('Something went wrong.');
      }
    };

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            ref={ref}
            variant={variant}
            size={size}
            className={cn('capitalize', className)}
            {...props}
          >
            {currLocale.flag} {currLocale.locale}{' '}
            <ChevronDownIcon className='w-4 h-4 ml-1' />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {LOCALES.map((l) => (
            <DropdownMenuItem
              key={l.locale}
              onClick={async () => await onSelectLocale(l.locale)}
              className='capitalize cursor-pointer'
            >
              {l.flag} {l.locale} ({l.language})
              {locale === l.locale && <CheckIcon className='w-4 h-4 ml-2' />}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
);
